<script setup lang="ts">
import { socketInstance } from "~/components/socket";
import { useGlobalStore } from "~/stores/globalStore";

const isConnected = ref(false);
const transport = ref("N/A");

const store = useGlobalStore();

if (socketInstance?.connected) {
  onConnect();
}

function onConnect() {
  isConnected.value = true;
  transport.value = socketInstance?.io.engine.transport.name!;

  socketInstance?.io.engine.on("upgrade", (rawTransport) => {
    transport.value = rawTransport.name;
  });
}

function onDisconnect() {
  isConnected.value = false;
  transport.value = "N/A";
}

socketInstance?.on("connect", onConnect);
socketInstance?.on("disconnect", onDisconnect);
socketInstance?.on("ping", () => {
  console.log("Ping received");
  socketInstance?.emit("pong");
});

onBeforeUnmount(() => {
  socketInstance?.off("connect", onConnect);
  socketInstance?.off("disconnect", onDisconnect);
});

const user = useUser();
const router = useRouter();

onBeforeMount(() => {
  // redirect to login if not authenticated
  if (!user.value) {
    router.push("/login");
  }
});

const me = useMe();
const api = useApi();
const { data } = await api.userInfo();
me.value = data;
const { hasSubscription } = useSub();
</script>

<template>
  <template v-if="!!user">
    <div class="justify-between bg-gray-50 fixed w-full flex md:hidden z-50 shadow-md">
      <div class="py-2 px-4" @click="store.toggleMenu">
        <Icon name="mingcute:menu-fill" size="30" />
      </div>
    </div>
    <div class="flex flex-1 h-[100vh]" :class="{}">
      <div
        v-if="hasSubscription"
        class="fixed left-0 top-[46px] md:top-0 md:relative z-50 shadow-md md:shadow-none"
        :class="{
          'hidden md:!flex': !store.menuOpen,
          'md:block': store.menuOpen,
        }"
      >
        <SideMenu />
      </div>

      <!-- top menu -->

      <div class="content flex-1 h-full bg-white">
        <NuxtPage />
      </div>
    </div>

    <TermsAcceptance />
  </template>
</template>
