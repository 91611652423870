<script lang="ts" setup>
import Logo from "./Logo.vue";

const me = useMe();
const route = useRoute();
const router = useRouter();

const shouldPrompt = computed(() => {
  return me.value && me.value.acceptedTerms === false;
});

const api = useApi();

const loading = ref(false);
const complete = ref(false);

async function acceptTerms() {
  loading.value = true;
  const { data } = await api.acceptTerms();
  me.value = data;
  loading.value = false;
  complete.value = true;
  setTimeout(() => {
    complete.value = false;
  }, 3000);
}
</script>

<template>
  <div v-if="shouldPrompt">
    <div
      class="p-4 fixed top-0 left-0 w-screen h-screen z-50 flex flex-col items-center justify-center bg-white"
      role="alert"
    >
      <NuxtLink href="/">
        <Logo />
      </NuxtLink>

      <div class="mt-8"></div>
      <div>
        <h1 class="text-3xl mb-4 serif text-center">We're happy you're here!</h1>
        <div class="max-w-md space-y-4">
          <p>
            By clicking "Accept", you agree to continue to use our services under the
            <NuxtLink to="/terms" target="_blank" class="underline">Terms of Use</NuxtLink>
            and
            <NuxtLink to="/privacy-policy" target="_blank" class="underline">Privacy Policy</NuxtLink>.
          </p>
          <div class="mt-6 text-center">
            <Button @click="acceptTerms" class="btn-primary w-full">
              <Icon name="fa-solid:check" class="mr-2" v-if="complete" />
              <Icon name="fa-solid:spinner" class="mr-2 animate-spin" v-if="loading" />
              Accept and Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
