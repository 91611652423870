<script lang="ts" setup>
import { formatRelativeTime } from "@/lib/date";

const api = useApi();
const { data } = useAsyncData("listChats", async () => {
  const { data } = await api.listChats();
  return data.reverse();
});

const first10Chats = computed(() => data.value?.slice(0, 10));
const restOfChats = computed(() => data.value?.slice(10));

const { setMenu } = useGlobalStore();

const me = useMe();

import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

const target = ref(null);

onClickOutside(target, () => {
  setMenu(false);
});

async function onLogout() {
  await $fetch("/login/google/logout");
  navigateTo("/");
}

const { hasSubscription } = useSub();
</script>

<template>
  <div
    class="flex h-screen overflow-auto flex-col justify-between border-e border-gray-200 bg-gray-50 w-[320px]"
    ref="target"
  >
    <div class="px-4 py-6 z-10 relative flex flex-col justify-between flex-1">
      <div>
        <a class="flex flex-row items-center gap-1 text-center justify-center py-4" href="#">
          <div class="serif text-black font-bold text-2xl">betterdanish</div>
        </a>

        <div class="mt-6 space-y-1">
          <NuxtLink :href="hasSubscription ? '/app/chat/new' : '#'">
            <Button class="w-full flex justify-start items-center gap-2" variant="ghost" :disabled="!hasSubscription">
              <Icon name="humbleicons:chat" size="24" />
              <div>Start a new chat</div>
            </Button>
          </NuxtLink>
          <div>
            <NuxtLink v-for="item in first10Chats" :key="item.id" :href="`/app/chat/${item.id}`">
              <TooltipProvider>
                <Tooltip :delay-duration="100">
                  <TooltipTrigger asChild>
                    <Button
                      size="sm"
                      variant="ghost"
                      class="w-full flex justify-between items-center font-normal"
                      :class="{
                        'bg-gray-200': $route.path === `/app/chat/${item.id}`,
                      }"
                    >
                      <div class="truncate">{{ item.name ?? formatRelativeTime(item.createdAt) }}</div>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div class="text-center">
                      {{ formatRelativeTime(item.createdAt) }}
                      <template v-if="!!item.name">- </template>
                      {{ item.name }}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </NuxtLink>
          </div>
          <Popover v-if="restOfChats && restOfChats?.length > 0">
            <PopoverTrigger asChild>
              <Button size="sm" class="w-full flex justify-between items-center" variant="ghost">
                <div>Older chats</div>
                <Icon name="tabler:chevron-right" />
              </Button>
            </PopoverTrigger>
            <PopoverContent class="p-0 max-w-56" side="right">
              <div>
                <NuxtLink v-for="item in restOfChats" :key="item.id" :href="`/app/chat/${item.id}`">
                  <Button size="sm" variant="ghost" class="w-full flex justify-between items-center font-normal">
                    <div class="truncate">{{ item.name ?? formatRelativeTime(item.createdAt) }}</div>
                  </Button>
                </NuxtLink>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <div class="mt-6">
        <NuxtLink href="/app/subscription/manage" v-if="me?.stripeCustomerId">
          <Button class="w-full flex justify-start gap-2" variant="ghost">
            <Icon name="tabler:credit-card" :size="20" />
            Manage subscription
          </Button>
        </NuxtLink>
      </div>
    </div>

    <div class="border-t-2 border-gray-200 z-20">
      <Popover>
        <PopoverTrigger asChild>
          <a href="#" class="flex gap-3 p-4 hover:bg-gray-50 text-left items-center">
            <img v-if="me?.picture" alt="" :src="me.picture" class="size-10 rounded-full object-cover" />

            <div>
              <p class="text-xs">
                <strong class="block font-medium">{{ me?.name }}</strong>
                <span> {{ me?.email }} </span>
              </p>
            </div>
          </a>
        </PopoverTrigger>
        <PopoverContent class="p-0 max-w-40" side="right">
          <Button size="sm" variant="ghost" class="w-full justify-start" @click="onLogout"> Log out </Button>
        </PopoverContent>
      </Popover>
    </div>
  </div>
</template>
